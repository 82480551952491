<template>
  <div class="point-withdraw" :class="{skeleton: !state.loaded}">
    <div class="wrapper">
      <div class="title font-md">
        <span>출금 요청</span>
      </div>
      <div class="desc font-sm">계좌 정보 입력 후 신청하기 버튼을 눌러주세요.</div>
      <div class="form">
        <div class="alert alert-warning" v-if="state.loaded && !state.point">출금 가능한 포인트가 없습니다.</div>
        <div class="form-group">
          <label :for="`${component.name}Point`" class="font-sm">
            <span>출금 가능 포인트</span>
          </label>
          <input type="text" class="form-control font-sm border-focus-point" :value="$lib.getNumberFormat(state.point)" disabled/>
        </div>
        <div class="form-group">
          <label :for="`${component.name}AccountBank`" class="font-sm">
            <span>입금 계좌</span>
          </label>
          <div class="row">
            <div class="col-4 pr-0">
              <select :id="`${component.name}AccountBank`" class="form-control font-sm border-focus-point" v-model="state.form.accountBank" :disabled="!state.point">
                <option value="">은행 선택</option>
                <option value="KDB 산업">KDB 산업</option>
                <option value="기업">기업</option>
                <option value="국민">국민</option>
                <option value="KEB 하나">KEB 하나</option>
                <option value="수협">수협</option>
                <option value="농협">농협</option>
                <option value="지역농축협">지역농축협</option>
                <option value="우리">우리</option>
                <option value="신한">신한</option>
                <option value="SC제일">SC제일</option>
                <option value="씨티">씨티</option>
                <option value="대구">대구</option>
                <option value="부산">부산</option>
                <option value="제주">제주</option>
                <option value="광주">광주</option>
                <option value="전북">전북</option>
                <option value="경남">경남</option>
                <option value="새마을금고">새마을금고</option>
                <option value="신협">신협</option>
                <option value="산립조합">산림조합</option>
                <option value="우체국">우체국</option>
                <option value="케이뱅크">케이뱅크</option>
                <option value="카카오뱅크">카카오뱅크</option>
              </select>
            </div>
            <div class="col-8">
              <input type="text" :id="`${component.name}AccountNum`" placeholder="계좌번호" class="form-control font-sm border-focus-point" v-model="state.form.accountNum" :disabled="!state.point"/>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label :for="`${component.name}AccountName`" class="font-sm">
            <span>예금주</span>
          </label>
          <input type="text" :id="`${component.name}AccountName`" class="form-control font-sm border-focus-point" placeholder="예) 오마이" v-model="state.form.accountName" :disabled="!state.point"/>
        </div>
        <div class="form-group">
          <label :for="`${component.name}Amount`" class="font-sm">
            <span>출금 요청 금액</span>
          </label>
          <Number :id="`${component.name}Amount`" placeholder="(단위: 원)" :value.sync="state.form.amount" :enter="submit" :disabled="!state.point"/>
        </div>
      </div>
    </div>
    <div class="action">
      <button :id="`${component.name}Submit`" class="btn btn-point btn-block font-sm" @click="submit()" :disabled="!state.point">요청하기</button>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import http from "@/scripts/http";
import store from "@/scripts/store";
import Number from "@/components/Number";
import mixin from "@/scripts/mixin";
import {httpError} from "@/scripts/httpError";

function Component(initialize) {
  this.name = "modalCashWithdraw";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {Number},
  setup() {
    const component = new Component(() => {
      state.loaded = false;
      http.get("/api/cash/withdraw").then(({data}) => {
        state.loaded = true;
        state.point = data.body;
      });

      store.commit("tuneModal", {component, size: "xs"});
    });

    const state = reactive({
      loaded: false,
      point: 0,
      form: {
        accountBank: "",
        accountNum: "",
        accountName: "",
        amount: null,
      }
    });

    const submit = () => {
      if (!state.form.accountBank) {
        document.getElementById(`${component.name}AccountBank`).focus();
        return store.commit("setSwingMessage", "은행을 선택해주세요.");
      } else if (!state.form.accountNum) {
        document.getElementById(`${component.name}AccountNum`).focus();
        return store.commit("setSwingMessage", "계좌번호를 입력해주세요.");
      } else if (!state.form.accountName) {
        document.getElementById(`${component.name}AccountName`).focus();
        return store.commit("setSwingMessage", "예금주를 입력해주세요.");
      } else if (!state.form.amount) {
        document.getElementById(`${component.name}Amount`).focus();
        return store.commit("setSwingMessage", "출금 요청 금액을 입력해주세요.");
      } else if (state.point < state.form.amount) {
        document.getElementById(`${component.name}Amount`).focus();
        return store.commit("setSwingMessage", "출금 가능 금액보다 큰 금액은 출금할 수 없습니다.");
      }

      http.post("/api/withdraws", state.form).then(() => {
        store.commit("closeModal", {
          name: component.name,
          onClose(modal) {
            store.dispatch("callback", {modal});
            store.commit("setSwingMessage", "출금 신청을 완료하였습니다.");
          }
        });
      }).catch(httpError());
    };

    return {component, state, submit};
  },
});
</script>

<style lang="scss" scoped>
.point-withdraw {
  > .wrapper {
    background: #fff;
    padding: $px25;

    .title {
      margin-bottom: $px9;
    }

    .desc {
      margin-bottom: $px25;
      color: #666;
    }

    .form {
      .alert {
        font-size: $px13;
        padding: $px15;
      }

      .form-group {
        margin-bottom: $px20;

        input[type=text], input[type=tel], input[type=password], select {
          height: $formHeight;
          width: 100%;
        }

        > .wrapper {
          display: table;
          table-layout: fixed;
          margin: 0 $px-5;
          width: 100%;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .action {
    button {
      padding: $px15;
      height: $px56;
      border-radius: 0;
    }
  }

  &.skeleton {
    input, select {
      @include skeleton;
    }
  }
}
</style>